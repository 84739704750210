import { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router';
import StepTerms from './StepTerms';
import StepEmail from './StepEmail';
import StepEmailCode from './StepEmailCode';
import StepName from './StepName';
import StepMobile from './StepMobile';
import StepPassword from './StepPassword';
import { Auth } from 'aws-amplify';

function CreateAccount() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [emailCode, setEmailCode] = useState('');
    const [mobile, setMobile] = useState('');

    const nav = useNavigate();

    const handleStepTermsNext = () => {
        nav('email');
    }

    const handleStepEmailNext = (email) => {
        setEmail(email);
        nav('name');
    }

    const handleStepNameNext = (firstName, lastName) => {
        setFirstName(firstName);
        setLastName(lastName);
        nav('mobile');
    }

    const handleStepEmailCodeNext = (emailCode) => {
        setEmailCode(emailCode);
        nav('mobile');
    }

    const handleStepMobileNext = (mobile) => {
        setMobile(mobile);
        nav('password');
    }

    const doCreateUser = async (password) => {
        const createResult = await Auth.signUp({
            username: email,
            password,
            attributes: {
                given_name: firstName,
                family_name: lastName,
                email,
                phone_number: mobile.phoneNumber,
                'custom:mobile_country': mobile.countryCode,
            },
        });


        nav('../complete');
    }

    return (
        <div className={'create-account-main-container'}>
            <div className={'header-container'}>
                Create an account
            </div>

            <Routes>
                <Route path={'/'} element={<StepTerms onNext={() => handleStepTermsNext()} />} />
                <Route path={'email'} element={<StepEmail onNext={(val) => handleStepEmailNext(val)} />} />
                <Route path={'name'} element={<StepName onNext={(firstName, lastName) => handleStepNameNext(firstName, lastName)} />} />
                <Route path={'emailcode'} element={<StepEmailCode onNext={(val) => handleStepEmailCodeNext(val)} />} />
                <Route path={'mobile'} element={<StepMobile onNext={(val) => handleStepMobileNext(val)} />} />
                <Route path={'password'} element={<StepPassword onNext={(val) => doCreateUser(val)} />} />
            </Routes>
        </div>
    );
}

export default CreateAccount;