import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'

export default function SuccessBox({ title, children, onDismiss }) {
    const handleOnDismissClick = () => {
        if (typeof (onDismiss) === 'function') {
            onDismiss();
        }
    }
    return <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
            <div className="flex-shrink-0">
                <CheckCircleIcon className="h-7 w-7 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
                <h3 className="text-base font-semibold ecc-text">{title}</h3>
                <div className="mt-2 text-base ecc-text">
                    {children}
                </div>
            </div>
            <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                    <button
                        onClick={handleOnDismissClick}
                        type="button"
                        className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                    >
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon className="h-5 w-5 ecc-text" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>
}