import Button from '@/components/Button';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

function EditPasswordConfirm({ showModal, callback }) {
    return <Transition.Root show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                            <Dialog.Title className={'font-semibold text-xl text-center '}>Password Update</Dialog.Title>
                            <Dialog.Description>

                            </Dialog.Description>

                            <p className={'mt-10 mb-10'}>
                                You are about to update your password. By clicking 'Confirm' button, you will be automatically logged out.
                                Would you like to proceed?
                            </p>
                
                            <div className={'mb-2'}>
                                <Button onClick={() => callback(true)}>
                                    Confirm
                                </Button>
                            </div>
                            <div className={'mb-5'}>
                                <Button type={'secondary'} onClick={() => callback(false)}>
                                    Cancel
                                </Button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
}

export default EditPasswordConfirm;