import { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";
import { selectAuthState, selectCurrentMFAPromt, selectPreferredMFA } from '../../stores/authSlice';
import { useSelector } from 'react-redux'

import TextInput from '@/components/TextInput';
import CheckBoxInput from '@/components/CheckBoxInput';
import Warning from '@/assets/warning.svg'


import SelectInput from '@/components/SelectInput';

import { useNavigate } from 'react-router';
import PhoneInput from '@/components/PhoneInput';
import titles from '@/assets/titles';
import getSignupCodeDetails from '@/getSignupCodeDetails';
import { getUrlParams } from '@/utils/getUrlParams';

import eccLoading from '@/assets/ecc-loading.gif';

function SignUpProfile() {
    const [currentUser, setCurrentUser] = useState();
    const [enrollSmsCode, setEnrollSmsCode] = useState();
    const [smsState, setSmsState] = useState();

    const [isLoading, setIsLoading] = useState(false);

    /* Form Fields */
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState();
    const [phoneNumber, setPhoneNumber] = useState();

    //const [mobilePhoneCountryCode, setMobilePhoneCountryCode] = useState();
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState();
    /* End Form Fields */

    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

    const [phoneError, setPhoneError] = useState(false);

    const [smsEnabled, setSmsEnabled] = useState();

    const [updateError, setUpdateError] = useState();

    const authState = useSelector(selectAuthState);
    const currentMFAPrompt = useSelector(selectCurrentMFAPromt);
    //const currentPreferredMFA = useSelector(selectPreferredMFA);

    const [currentPreferredMFA, setCurrentPreferredMFA] = useState();

    const [error, setError] = useState();

    const nav = useNavigate();



    useEffect(() => {
        const { signup_code } = getUrlParams();

        setIsLoading(true);

        getSignupCodeDetails(signup_code, false).then((details) => {
            setIsLoading(false);
            if (details.error) {
                setError(details.error);
            }
            else {
                setUsername(details.email);
                setFirstName(details.first_name || '');
                setLastName(details.last_name || '');
            }
        })
    }, []);

    useEffect(() => {
        if (password !== confirmPassword) {
            setPasswordError(true);
            setPasswordErrorMessage('Passwords do not match');
            return;
        }

        if (password.length < 10 || !/\d{2,}/gm.test(password)) {
            setPasswordError(true);
            setPasswordErrorMessage('Password does not meet complexity requirements');
            return;
        }

        setPasswordError(false);
        setPasswordErrorMessage('');

    }, [password, confirmPassword]);

    useEffect(() => {

    }, [mobilePhoneNumber])


    const hasChanged = (attrName, value) => {
        return currentUser.attributes[attrName] !== value;
    }

    const createProfile = async () => {
        const { signup_code } = getUrlParams();

        if (!signup_code) {
            setError('Sign-in link invalid or expired. Please click here to request another link.');
            return;
        }

        const details = await getSignupCodeDetails(signup_code, true);

        const newUserDetails = {
            username: details.email,
            password,
            attributes: {
                'custom:title': title,
                given_name: firstName,
                middle_name: middleName,
                family_name: lastName,
                email: details.email,
                phone_number: mobilePhoneNumber.phoneNumber,
                'custom:mobile_country': mobilePhoneNumber.countryCode,

                'custom:landline_number': phoneNumber.phoneNumber,
                'custom:landline_country': phoneNumber.countryCode,

                //'custom:profile_complete': "1"
            },
        };

        const createResult = await Auth.signUp(newUserDetails);

        console.log('result', createResult)

        nav('../complete')
    }


    const doVerifyPhoneNumber = () => {
        Auth.verifyUserAttribute(currentUser, 'phone_number').then(() => {
            setSmsState('sent');
        })
    }

    const handleSMSButtonClick = () => {
        if (hasChanged('phone_number'), phoneNumber) {
            Auth.updateUserAttributes(currentUser, { phone_number: phoneNumber }).then(() => {
                doVerifyPhoneNumber();
            })
        }
        else {
            doVerifyPhoneNumber();
        }
    }

    const checkAllowNext = () => {
        return !passwordError;
    }

    const requestNewCode = () => {
        //`https://eccdev04.service-now.com/api/x_acn62_intg_core/user_invitation_code/reissue/`
    }

    const getErrorMessage = () => {
        if (error === 'CODE_EXPIRED') {
            return <span> Sign-in link expired. Please <a href='' onClick={() => requestNewCode()}>click here</a> to request another link.</span>
        }

        if (error === 'CODE_NOT_SUPPLIED' || error === 'CODE_NOT_FOUND') {
            return <span> Sign-in link is missing or invalid</span>
        }

        if (error === 'CODE_USED') {
            return <span> Sign-in link has been used. Please <a href=''>click here</a> if you beleive this to be in error.</span>
        }

    }

    /* If isLoading, we show the spinner screen instead */
    if (isLoading) {
        return <div className={'signin-parent-container'}>
            <div className={'signin-label'}>Sign in to FoodTrader</div>
            <div className={'overlayImg'}><img src={eccLoading} /></div>
        </div>
    }

    /* If we have an error, render title and error message */
    if (error && error !== '') {
        return <div className={'signin-parent-container'}>
            <div className={'signin-label'}>FoodTrader Profile</div>
            <div>Before logging in for the first time, you need to complete your profile</div>
            <div className={'error-container'}><img src={Warning} />{getErrorMessage()}</div>
        </div>
    }

    return <div className={'signin-parent-container'}>
        <div className={'signin-label'}>FoodTrader Profile</div>
        <div>Before logging in for the first time, you need to complete your profile</div>
        {error && error !== "" && <div className={'error-container'}><img src={Warning} />{error}</div>}
        <div className={'input-container'}>

            <SelectInput label={'Title'} value={title} onChange={(value) => setTitle(value)} options={titles} />

            <TextInput label={'First Name'} value={firstName} onChange={(value) => setFirstName(value)} />
            <TextInput label={'Middle Name'} value={middleName} onChange={(value) => setMiddleName(value)} />
            <TextInput label={'Last Name'} value={lastName} onChange={(value) => setLastName(value)} />

            <TextInput label={'Email'} value={username} onChange={(value) => setUsername(value)} readonly={true} />

            <TextInput label={'Password'} value={password} onChange={(value) => setPassword(value)} hidden={true} />
            <TextInput label={'Confirm Password'} value={confirmPassword} onChange={(value) => setConfirmPassword(value)} hidden={true} />
            {passwordError && <label className={'signup-input-error'}>{passwordErrorMessage}</label>}

            <PhoneInput label={'Phone Number'} value={phoneNumber} onChange={(value) => setPhoneNumber(value)} />
            <PhoneInput label={'Mobile Number'} value={mobilePhoneNumber} onChange={(value) => setMobilePhoneNumber(value)} />
            {phoneError && <label className={'signup-input-error'}>Please enter a valid mobile phone number</label>}

            {false && <CheckBoxInput label={'Enable SMS Multifactor Authentication'} value={smsEnabled} onChange={(val) => setSmsEnabled(val)} />}

            {smsEnabled && currentPreferredMFA !== 'SMS_MFA' && <div>
                <label>To enable SMS based MultiFactor Authentication, a SMS code needs to be sent to your phone and veified.</label>
                <button className={'btn btn-link btn-sm'} onClick={handleSMSButtonClick}>Send SMS</button>xs
                {smsState === 'sent' && <TextInput label={'MFA Code'} value={enrollSmsCode} onChange={(value) => setEnrollSmsCode(value)} />}
            </div>}

            <button type={'button'} className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => createProfile()}>Create my Account</button>
        </div>
    </div>
}

/*
         <TextInput label={'Password'} value={password} hidden={true} onChange={(value) => setPassword(value)} />
           
            <TextInput label={'Address'} value={address} onChange={(value) => setAddress(value)} />

            <div className={'form-row'}>
                <div className={'col-md-6'}>
                    <TextInput label={'Suburb'} value={suburb} onChange={(value) => setSuburb(value)} />
                </div>
                <div className={'col-md-4'}>
                    <SelectInput label={'State'} options={States} value={state} onChange={(value) => setState(value)} />
                </div>
                <div className={'col-md-2'}>
                    <TextInput label={'Post Code'} value={postCode} onChange={(value) => setPostCode(value)} />
                </div>
            </div>
         
            {authState === 'sms_mfa' && <label>An SMS code has been sent to {authResult?.challengeParam?.CODE_DELIVERY_DESTINATION}</label>}
            {authState === 'sms_mfa' && <TextInput label={'MFA Code'} linkText={'Forgot username?'} value={mfaCode} onChange={(value) => setMfaCode(value)} />}
       {authState === 'incorrect' && <div className={'error-container'}><img src={Warning} />The email address or password you have entered is incorrect.</div>}
        {authState === 'unconfirmed' && <div className={'error-container'}><img src={Warning} />The email address has not been confirmed. Resend confirmation email</div>}
 
            */


export default SignUpProfile;