import TextInput from "@/components/TextInput";
import { Auth } from "aws-amplify";
import { useState } from "react";
import Button from '@/components/Button';

function EditEmailConfirm({ currentUser, newEmail, onComplete }) {
    const [code, setCode] = useState('');


    const handleConfirmClick = () => {
        //{"__type":"ExpiredCodeException","message":"Invalid code provided, please request a code again."}
        Auth.verifyUserAttributeSubmit(currentUser, 'email', code)
            .then(() => {
                onComplete(true);
            }).catch((ex) => {
                console.error(ex.message)
            })
    }

    return <div>
        <div className={'ecc-text text-2xl font-semibold my-4'}>Enter Code</div>
        <p className={'mb-4'}>We've sent a code by email to your email address {newEmail}</p>

        <div className={'mb-4'}>
            <TextInput label={'Code'} onChange={setCode} />
        </div>

        <p>Did not receive the code?</p>
        <a href={''}>Resend Code</a>



        <div className={'my-4'}>
            <Button variant="primary" onClick={handleConfirmClick}>
                Confirm
            </Button>
            <Button type={"secondary"} >
                Cancel
            </Button>
        </div>
    </div>
}

export default EditEmailConfirm;