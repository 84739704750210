import { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router';
import StepEmail from './StepEmail';
import StepPassword from './StepPassword';
import Complete from './complete';
import { Auth } from 'aws-amplify';
import StepEmailCode from './StepEmailCode';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [passwordException, setPasswordException] = useState('');

    const nav = useNavigate();

    const handleStepEmailNext = (email) => {
        setEmail(email);

        Auth.forgotPassword(email)
            .then((res) => {
                console.log(res);
                nav('password');
            })
            .catch((err) => {
                console.error(err);
                nav('password');
            })
    }

    const handleStepEmailCodeNext = (emailCode) => {
        //setEmailCode(emailCode);
        /* TODO: code verification */

        nav('password');
    }

    const doUpdateAccount = async (emailCode, password) => {
        Auth.forgotPasswordSubmit(email, emailCode, password).then((res) => {
            nav('complete');
        })
            .catch((ex) => {
                setPasswordException(ex.code);
            })
    }

    return (
        <div>
            <div className={'header-container'}>
                Reset Password
            </div>

            <Routes>
                <Route path={'/'} element={<StepEmail onNext={(val) => handleStepEmailNext(val)} />} />
                <Route path={'code'} element={<StepEmailCode onNext={(val) => handleStepEmailCodeNext(val)} />} />
                <Route path={'password'} element={<StepPassword passwordException={passwordException} onNext={(emailCode, password) => doUpdateAccount(emailCode, password)} />} />
                <Route path={'complete'} element={<Complete />} />
            </Routes>
        </div>
    );
}

export default ResetPassword;
