import { useEffect, useState } from 'react';

import TextInput from "@/components/TextInput";
import { useNavigate } from 'react-router';
import { Auth } from 'aws-amplify';
import EditEmailConfirm from './EditEmailConfirm';
import EditEmailSuccessful from './EditEmailSuccessful';
import { authLogOut } from '@/utils/authLogOut';
import { checkEmailAvailable } from '@/utils/checkEmailAvailable';
import ErrorContainer from '@/components/ErrorContainer';
import Button from '@/components/Button';
import InfoBox from '@/components/InfoBox';

function EditEmail({ currentUser }) {
    const nav = useNavigate();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [email, setEmail] = useState('');

    const [emailUsed, setEmailUsed] = useState(false);

    useEffect(() => {
        setEmailUsed(false);
    }, [email])

    const handleSendClick = async () => {
        const canUseEmail = await checkEmailAvailable(email);

        if (canUseEmail) {
            const updateResult = await Auth.updateUserAttributes(currentUser, { email });
            console.log('Update: ', updateResult)
            setShowConfirm(true);
            return;
        }

        setEmailUsed(true);
    }

    const handleConfirmOnComplete = (result) => {
        if (result) {
            setShowConfirm(false);
            authLogOut().then(() => {
                setShowSuccessModal(true);
            })
        }
    }

    if (showConfirm) {
        return <EditEmailConfirm newEmail={email} currentUser={currentUser} onComplete={handleConfirmOnComplete} />
    }

    const handleModalCallback = () => {
        setShowSuccessModal(false);
        window.location.pathname = '/';
    }

    return <>
        <div>
            <div className={'my-5 ecc-text text-2xl font-semibold'}>Email Address</div>

            <p className={'my-5'}>This email address is your username.</p>

            <div className={'bg-neutral-100 p-5 border border-top border-x-0 border-b-0'}>
                <span className={'font-semibold'}>Current Email</span>
                <br />
                <span>{currentUser?.attributes?.email}</span>
            </div>

            <div className={'text-xl mt-8 pb-2 border border-bottom border-l-0 border-r-0 border-t-0'}>Update your Email Address</div>
            <p className={'my-4'}>To keep this account secure, you will be logged out and will need to use your new email to log in.</p>

            <TextInput label={'New email address'} value={email} onChange={setEmail} />
            {emailUsed && <ErrorContainer message={'This email address is already in use'} />}

            <div className={'my-4'}>
                <InfoBox>Verification code will be sent to new email</InfoBox>
            </div>

            <div className="d-grid gap-2">
                <Button variant="primary" onClick={handleSendClick}>
                    Send Code
                </Button>
            </div>
        </div>

        <EditEmailSuccessful showModal={showSuccessModal} email={email} callback={handleModalCallback} />
    </>
}

export default EditEmail;