export default function Button({ onClick, type = 'primary', disabled = false, children }) {
    console.log('[Button]', disabled);

    const colourClasses = ['w-full', 'h-12', 'rounded', 'px-2', 'py-1', 'focus-visible:outline', 'focus-visible:outline-2', 'focus-visible:outline-offset-2', 'shadow-sm'];

    if (type === 'primary' && !disabled) {
        colourClasses.push('text-white');
        colourClasses.push('bg-blue-700');
        colourClasses.push('hover:bg-blue-750');
    }
    else if (type === 'secondary' && !disabled) {
        colourClasses.push('ecc-text');
    }
    else if (type === 'primary' && disabled) {
        colourClasses.push('text-white');
        colourClasses.push('bg-blue-400');
    }
    else if (type === 'secondary' && disabled) {
        colourClasses.push('ecc-text');
    }


    const handleClick = () => {
        if (disabled || !onClick) {
            return;
        }

        onClick();
    }

    return <button
        disabled={disabled}
        className={colourClasses.join(' ')}
        onClick={handleClick}
    >
        {children}
    </button>
}