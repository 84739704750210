import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function PasswordInput({ label, value, linkClick, linkText, onChange, onEnterClick, readonly }) {
    const [id] = useState(_uniqueId('input-'));
    const [isHidden, setIsHidden] = useState(true)

    const handleKeyUp = (event) => {
        if (event.keyCode === 13 && typeof (onEnterClick) === 'function') {
            onEnterClick();
        }
    }

    return <div>
        <label htmlFor="email" className="block text-base font-semibold leading-6 text-gray-900">
            {label}
        </label>
        <div className="mt-2 flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                    type={isHidden ? 'password' : 'text'} readOnly={readonly}
                    name="email"
                    id={id}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyUp={handleKeyUp}
                    className="block w-full rounded-none rounded-l-md border-0 py-3 pl-4 text-gray-900 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 text-base"
                />
            </div>
            <button
                type="button"
                onClick={() => setIsHidden(!isHidden)}
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-600 hover:bg-gray-50"
            >
                <ion-icon name={isHidden ? 'eye-outline' : 'eye-off-outline'}></ion-icon>
            </button>
        </div>
    </div>
}

export default PasswordInput;