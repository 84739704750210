export async function checkEmailAvailable(emailAddress) {

    let fetchUrl = '';

    if (window.location.hostname.toString() === 'localhost') {
        fetchUrl = `https://poc-auth.eccnow.com.au/accountCheck?email=${emailAddress}`;
    }
    else {
        fetchUrl = `https://${window.location.hostname.toString()}/accountCheck?email=${emailAddress}`;
    }

    const rawData = await fetch(fetchUrl);
    const jsonData = await rawData.json();

    return jsonData.canUse;
}