
import React from 'react';
import Warning from '@/assets/warning.svg';

function ErrorContainer({ message, useBorder }) {
    let mainClassNames = ['error-container'];

    if (useBorder) {
        mainClassNames.push('error-container-border')
    }
    return <div>
        <div className={mainClassNames.join(' ')} style={{ display: 'flex' }}><img src={Warning} style={{ marginTop: '3px' }} /><label>{message}</label></div>
    </div>
}

export default ErrorContainer;