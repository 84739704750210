export default function InfoBox({ children }) {
    return <div className="rounded-md bg-blue-50 py-3 px-2">
        <div className="flex">
            <div className="flex-shrink-0">

            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-base font-medium ecc-text">{children}</p>
            </div>
        </div>
    </div>
}