import Modal from 'react-bootstrap/Modal';
import Button from '@/components/Button';
import success from '@/assets/success.svg';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';


function EditEmailSuccessful({ showModal, callback, email }) {
    return <Transition.Root show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                            <img className={'mx-auto h-20 w-20 mt-12 mb-2'} src={success} />


                            <div className={'my-8'}>
                                <p className={'signin-expired-body mb-8'}>Your email has been successfully updated to:<br /><strong>{email}</strong></p>
                                <p className={'signin-expired-body'}>You can now login with your updated email ID.</p>
                            </div>
                            <div className={'mb-8'}>
                                <Button onClick={() => callback(true)}>
                                    Login to FoodTrader account
                                </Button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
}

export default EditEmailSuccessful;