import TextInput from "@/components/TextInput";
import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import EditNameModal from "./EditNameModal";
import Alert from 'react-bootstrap/Alert';

import Button from '@/components/Button';
import SuccessBox from "@/components/SuccessBox";
import ErrorBox from "@/components/ErrorBox";

function EditName({ currentUser, refreshUser }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCompleteAlert, setShowCompleteAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const [firstName, setFirstName] = useState(currentUser?.attributes?.given_name || '');
    const [middleName, setMiddleName] = useState(currentUser?.attributes?.middle_name || '');
    const [lastName, setLastName] = useState(currentUser?.attributes?.family_name || '');

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        if (!firstName) {
            setFirstName(currentUser?.attributes?.given_name);
        }

        if (!middleName) {
            setMiddleName(currentUser?.attributes?.middle_name);
        }

        if (!lastName) {
            setLastName(currentUser?.attributes?.family_name);
        }

    }, [currentUser]);

    const handleModalCallback = (result) => {
        setShowConfirmModal(false);

        if (result) {
            const valuesToUpdate = {};
            valuesToUpdate.given_name = firstName;
            valuesToUpdate.middle_name = middleName;
            valuesToUpdate.family_name = lastName;

            Auth.updateUserAttributes(currentUser, valuesToUpdate)
                .then((result) => {
                    refreshUser();
                    setShowCompleteAlert(true);
                })
                .catch((ex) => setShowErrorAlert(true));
        }
    }

    const getFullName = () => {
        return `${firstName} ${middleName} ${lastName}`
    }

    const canSave = () => {
        return firstName !== '' && lastName !== '';
    }

    return <div className={''}>
        {showCompleteAlert && <SuccessBox title={'Your Full Name Update Successfully'} onDismiss={() => setShowCompleteAlert(false)}>
            <p>
                You have successfully updated your full name.
            </p>
        </SuccessBox>
        }
        {showErrorAlert && <ErrorBox title={'Your Full Name Update was Unsuccessful'} onDismiss={() => setShowErrorAlert(false)}>Update unsuccessful, please contact</ErrorBox>}

        <div className={'px-4 my-5 ecc-text text-2xl font-semibold'}>Your Full Name</div>

        <div className={'bg-neutral-100 p-5 border border-top border-x-0 border-b-0'}>
            <p>Your full name defaults recorded with FoodTrader are:</p>
            <ul className={'list-disc pl-8'}>
                <li>First Name: <span className={'font-semibold'}>{currentUser?.attributes?.given_name}</span></li>
                <li>Middle Name: <span className={'font-semibold'}>{currentUser?.attributes?.middle_name}</span></li>
                <li>Last Name: <span className={'font-semibold'}>{currentUser?.attributes?.family_name}</span></li>
            </ul>
        </div>

        <div className={'px-4 mt-6'}>
            <span><span className={'font-semibold'}>Last Updated: </span>2 July 2022 1:09:23 PM</span>
        </div>

        <div class="p-4">
            <div class="my-2">
                <TextInput label={'First Name *'} value={firstName} onChange={setFirstName} />
            </div>
            <div class="my-2">
                <TextInput label={'Middle Name'} value={middleName} onChange={setMiddleName} />
            </div>
            <div class="my-2">
                <TextInput label={'Last Name *'} value={lastName} onChange={setLastName} />
            </div>
        </div>

        {!canSave() && <div className={'p-4'}>
            <ErrorBox>
                First and Last name are required
            </ErrorBox>
        </div>}

        <div className="p-4">
            <Button disabled={!canSave()} onClick={() => setShowConfirmModal(true)}>
                Update
            </Button>
        </div>

        <EditNameModal showModal={showConfirmModal} fullName={`${firstName} ${middleName} ${lastName}`} callback={handleModalCallback} />
    </div>
}

export default EditName;