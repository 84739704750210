import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import success from '@/assets/success.svg';

function ProfileCreated() {
    const nav = useNavigate();

    return <div>

        <div className={'grid grid-cols-1 place-content-center'}>
            <div className={'center h-6 w-6'}>
                <img src={success} />
            </div>
            <div className={'complete-container-text'}>Account Created!</div>
            <div className={'complete-container-subtext'}>Your account has been created successfuly on FoodTrader.. You will need to confirm your email address prior to logging in. </div>
        </div>
        <div className={'block-button-container'}>
            <button type={'button'} onClick={() => nav('../signin')} className={'btn btn-primary btn-block'}>Return to Login</button>
        </div>

    </div>
}


export default ProfileCreated;