import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '@/components/TextInput';
import Warning from '@/assets/warning.svg';

function StepPassword({ onNext, passwordException }) {
    const nav = useNavigate();

    const [emailCode, setEmailCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordSecond, setPasswordSecond] = useState('');
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (password !== passwordSecond) {
            setHasError(true);
            setError('Passwords do not match');
            return;
        }

        if (password.length < 10 || !/\d{2,}/gm.test(password)) {
            setHasError(true);
            setError('Password does not meet complexity requirements');
            return;
        }

        setHasError(false);
        setError('');

    }, [password, passwordSecond]);

    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext(emailCode, password);
    }

    const checkAllowNext = () => {
        return !hasError;
    }

    return <div>
        <label className={'create-account-step-label'}>Step 2 of 2 Verification Code and Password</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Choose a password</div>

            <p>
                If the email entered matches an account in Foodtrader, you will receive an email with a code to reset your account.
                Please enter that code below.
                [Resend button]
            </p>

            <TextInput label={'Enter Code'} onChange={(val) => setEmailCode(val)} />
            <p className={'additional-info-label'}>It may take a minute to recewive your code.
                Haven’t received it? Resend a new code</p>
            {passwordException === 'CodeMismatchException' || passwordException === 'UserNotFoundException' && <div className={'error-container'}><img src={Warning} />Code incorrect. <a href={''}>Resend</a></div>}

            <p>
                Your password must:
                <ul>
                    <li>have at least 10 characters</li>
                    <li>include at least 2 numbers</li>
                </ul>
            </p>

            <TextInput label={'Password'} hidden={true} onChange={(val) => setPassword(val)} />
            <TextInput label={'Re-enter Password'} hidden={true} onChange={(val) => setPasswordSecond(val)} />
            {hasError && <label className={'signup-input-error'}>{error}</label>}
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
        </div>
    </div>
}

export default StepPassword;