import { useState } from 'react';
import TextInput from "@/components/TextInput";
import { Routes, Route, useNavigate } from 'react-router';
import { Auth } from "aws-amplify";
import EditMobileConfirm from './EditMobileConfirm';
import InfoBox from '@/components/InfoBox';
import Button from '@/components/Button';

function EditMobile({ currentUser }) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [code, setCode] = useState('');

    const nav = useNavigate();

    const handleUpdateClick = () => {
        Auth.updateUserAttributes(currentUser, { phone_number: mobileNumber }).then(() => {
            Auth.verifyUserAttribute(currentUser, 'phone_number').then(() => {
                //nav('./confirm');
                setShowConfirm(true);
            })
        })
    }

    if (showConfirm) {
        return <EditMobileConfirm currentUser={currentUser} />
    }

    return <div className={'signin-info-container'}>
        <div className={'signin-subtitle-text subtitle-margin'}>Mobile Number</div>
        Minim veniam, quis nostrud exercitation ullamco.

        <div className={'text-block background-block my-2'}>
            <strong>Current Mobile Number</strong>
            <p>{currentUser?.attributes?.phone_number}</p>
        </div>

        <div className={'signin-subtitle-text subtitle-margin'}>Update your Mobile Number</div>
        <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

        <div className={'mt-2 mb-2'}>
            <TextInput label={'New mobile number'} onChange={setMobileNumber} />
        </div>

        <div className={'mt-2 mb-10'}>
            <InfoBox>Verification code will be sent to new mobile number.</InfoBox>
        </div>

        <div className="d-grid gap-2">
            <Button onClick={handleUpdateClick}>
                Send Code
            </Button>
        </div>
    </div>
}

export default EditMobile;