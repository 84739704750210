import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from '@/components/TextInput';
import ErrorContainer from '@/components/ErrorContainer';

function StepName({ onNext }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const nav = useNavigate();

    useEffect(() => {
        setHasError(false);
        setErrorMessage('');

        if (!checkAllowNext()) {
            setHasError(true);
            setErrorMessage('Your first name and last name are required.')
        }
    }, [firstName, lastName])

    const handleNext = async () => {
        if (!checkAllowNext()) {
            return;
        }

        return onNext(firstName, lastName);
    }

    const checkAllowNext = () => {
        return firstName !== '' && lastName !== '';
    }

    return <div>
        <label className={'create-account-step-label'}>Step 2 of 5 Email</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Enter your name</div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>
            <TextInput label={'First Name'} onChange={(val) => setFirstName(val)} />
            <TextInput label={'Last Name'} onChange={(val) => setLastName(val)} />
            {hasError && <ErrorContainer message={errorMessage} />}
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
        </div>
    </div>
}

export default StepName