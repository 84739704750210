import zxcvbn from 'zxcvbn';

export function checkPassword(passwordInput) {
    const passwordResult = zxcvbn(passwordInput);

    if (passwordResult.score < 2 || passwordInput.length < 8 || !/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(passwordInput)) {
        return { error: true, message: 'Password does not meet complexity requirements', feedback: passwordResult.feedback?.warning }
    }

    return { error: false }
}