/*
*/

import { getUrlParams } from "./getUrlParams";

export async function getProviderFile(email) {
    const { client_id, source_portal } = getUrlParams();

    let fetchUrl = '';

    if (window.location.hostname.toString() === 'localhost') {
        return { type: 'local' }
        fetchUrl = `https://obzzz78tl3.execute-api.ap-southeast-2.amazonaws.com/poc/identityproviders?client_id=${client_id}&source_portal=${source_portal}&email=${email}`;
    }
    else {
        fetchUrl = `https://${window.location.hostname.toString()}/identityproviders/?client_id=${client_id}&source_portal=${source_portal}&email=${email}`;
    }

    const rawData = await fetch(fetchUrl);
    const jsonData = await rawData.json();
    return jsonData;
}