import React, { useState, useEffect } from 'react';
import _uniqueId from 'lodash/uniqueId';
import countries from '@/assets/countries';
import countryCodes from '@/assets/countrycodes';

function findCountryByCode(code) {
    let foundCountry = '';

    Object.keys(countryCodes).forEach(key => {
        if (countryCodes[key] === code) {
            foundCountry = key;
        }
    });

    return foundCountry;
}

function PhoneInput({ label, value, linkClick, linkText, onChange, hidden, onEnterClick }) {
    const [id] = useState(_uniqueId('input-'));
    const [internalValue, setInternalValue] = useState();
    const [currentCountry, setCurrentCountry] = useState('AU');
    const [countryList] = useState(Object.keys(countries).map(k => k).sort((a, b) => a - b));

    const handleKeyUp = (event) => {
        if (event.keyCode === 13 && typeof (onEnterClick) === 'function') {
            onEnterClick();
        }
    }

    const handleOnChange = (textValue) => {
        onChange({ countryCode: currentCountry, phoneNumber: `+${countryCodes[currentCountry]}${textValue}` })
    }

    return <div className={'form-group'}>
        <div className={'input-label-container'}>
            <label htmlFor={id}>{label}</label>
            <small id={`${id}-link`} className={'form-text text-muted link-text'} onClick={() => linkClick()}>{linkText}</small>
        </div>

        <div className={'form-control phone-input-container'}>
            <select style={{ border: 'none' }} value={currentCountry} onChange={(e) => setCurrentCountry(e.target.value)}>
                {countryList.map(key => <option>{key}</option>)}
            </select>
            <div className={'vertical-bar'} />
            <label style={{ paddingLeft: '5px', paddingRight: '5px' }}>(+{countryCodes[currentCountry]})</label>
            <input type={'text'} style={{ border: 'none' }} id={id} value={internalValue} onChange={(e) => handleOnChange(e.target.value)} onKeyUp={handleKeyUp} />
        </div>
    </div>
}

export default PhoneInput;