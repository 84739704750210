/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_25SmPz460",
    "aws_user_pools_web_client_id": "4n2spjgng98u0j8rker3okjdii",
    "oauth": {
        "domain": "poc-idp.eccnow.com.au",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
            "mediaurlserver/url.get",
            "mediaurlserver/url.put"
        ],
        "redirectSignIn": "https://poc-auth.eccnow.com.au",
        "redirectSignOut": "https://poc-auth.eccnow.com.au/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "oscar-auth-broker-ui-poc",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://poc-auth.eccnow.com.au",
    "aws_cloud_logic_custom": [
        {
            "name": "amplifyIdentityBrokerApi",
            "endpoint": "https://tmekrovf6l.execute-api.ap-southeast-2.amazonaws.com/poc",
            "region": "ap-southeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "IdentityBrokerCode",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "IdentityBrokerClient",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
