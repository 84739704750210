import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { useNavigate } from 'react-router';
import TextInput from '@/components/TextInput';
import CheckboxInput from './components/CheckBoxInput';


function MFAEntry({ onResetPassword }) {
    const [phone, setPhone] = useState('');
    const [enableMFA, setEnableMFA] = useState(false);
    const [disablePrompt, setDisablePrompt] = useState(false);

    const [mfaCode, setMfaCode] = useState('');
    const [currentState, setCurrentState] = useState('start');

    const nav = useNavigate();

    const handleContinueClick = () => {
        if (enableMFA && currentState === 'start') {
            /* Trigger a verify phone number request */
            Auth.currentAuthenticatedUser().then((user) => {
                setPhone(user.attributes.phone_number);
                Auth.verifyUserAttribute(user, 'phone_number').then((result) => result === 'SUCCESS' ? setCurrentState('sms_sent') : setCurrentState('sms_error'));

                // Auth.setPreferredMFA(user, "SMS_MFA").then((result) => console.log(result))
            })
        }
        else if (enableMFA && currentState === 'sms_sent') {
            /* We now have the SMS response code, time to trigger a verify attribute submit, then turn MFA on */
            Auth.currentAuthenticatedUser().then((user) => {
                Auth.verifyCurrentUserAttributeSubmit('phone_number', mfaCode)
                    .then(() => {
                        Auth.setPreferredMFA(user, 'SMS_MFA').then((mfaResult) => {
                            if (mfaResult === 'SUCCESS') {
                                setCurrentState('mfa_successful')
                            }
                        })
                    })
            });
        }
        else if (currentState === 'mfa_successful') {
            // Do redirect
            nav('../loggedin');
        }
        else if (disablePrompt && !enableMFA) {
            /* We're not doing MFA and need to disable the prompt for future requests */

            const newAttrs = {};
            newAttrs['custom:mfa_prompt'] = '0';

            Auth.currentAuthenticatedUser().then((user) => {
                Auth.updateUserAttributes(user, newAttrs).then(() => {
                    nav('../loggedin');
                });
            });
        }
        else {
            /* We're not doing MFA */
            nav('../loggedin');

        }
    }

    return <div className={'signin-parent-container'}>
        <div className={'signin-label'}>Sign in to FoodTrader</div>
        <div className={'input-container'}>
            {currentState === 'start' && <div>
                For security purposes, it is recommended to enable Two Factor Authentication on your account.
                <CheckboxInput label={'Enable MFA'} onChange={setEnableMFA} value={enableMFA} />
                <CheckboxInput label={'Do not ask again'} onChange={setDisablePrompt} value={disablePrompt} />
            </div>}

            {currentState === 'sms_sent' && <div>
                An SMS has been sent to {phone} containing a 6 digit code. Enter that code below.
                <TextInput label={'MFA Code'} value={mfaCode} onChange={(value) => setMfaCode(value)} />
            </div>}

            {currentState === 'mfa_successful' && <div>
                MFA Signup Sucessful! Continue to log in.
            </div>}


            <button type={'button'} className={'btn btn-primary btn-block'} onClick={handleContinueClick}>Continue</button>
        </div>
    </div>
}


export default MFAEntry;

