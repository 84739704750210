
import { Auth } from 'aws-amplify';
import { eraseCookie } from '@/utils/cookieHelper';

export async function authLogOut() {
    try {
        eraseCookie("id_token");
        eraseCookie("access_token");
        eraseCookie("refresh_token");
        localStorage.removeItem('client-id');

        await Auth.signOut();
        return true;
    }
    catch (ex) {
        console.error(ex);
        return false;
    }

}