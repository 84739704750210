import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from '@/components/TextInput';

import zxcvbn from 'zxcvbn';
import ErrorContainer from '@/components/ErrorContainer';

function StepPassword({ onNext }) {
    const nav = useNavigate();

    const [password, setPassword] = useState('');
    const [passwordSecond, setPasswordSecond] = useState('');
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');

    const [passwordComplexity, setPasswordComplexity] = useState(0);
    const [passwordFeedback, setPasswordFeedback] = useState("");

    useEffect(() => {
        const passwordResult = zxcvbn(password);
        // console.log('ZXCVBN:', passwordResult);

        setPasswordComplexity(passwordResult.score);

        if (password !== passwordSecond) {
            setHasError(true);
            setError('Passwords do not match');
            return;
        }

        if (passwordResult.score < 2 || password.length < 8 || !/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(password)) {
            setHasError(true);
            setError('Password does not meet complexity requirements');
            setPasswordFeedback(passwordResult.feedback?.warning);
            return;
        }

        if (passwordResult.score < 2) {
            setHasError(true);
            setError('Password does not meet complexity requirements');
            setPasswordFeedback(passwordResult.feedback?.warning);
            return;

        }

        setPasswordFeedback('');
        setHasError(false);
        setError('');

    }, [password, passwordSecond]);

    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext(password);
    }

    const checkAllowNext = () => {
        return !hasError;
    }

    return <div>
        <label className={'create-account-step-label'}>Step 5 of 5 Password</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Choose a password</div>
            <p>
                Your password must:
                <ul>
                    <li>have at least 8 characters</li>
                    <li>include at least 1 upper case letter</li>
                    <li>include at least 1 lower case letter</li>
                    <li>include at least 1 number</li>
                    <li>include at least 1 special character</li>
                    <li>cannot contain common or repeated words or patterns</li>
                </ul>
            </p>
            <TextInput label={'Password'} hidden={true} onChange={(val) => setPassword(val)} />
            {hasError && <ErrorContainer message={`${error}. ${passwordFeedback}`} />}
            <TextInput label={'Re-enter Password'} hidden={true} onChange={(val) => setPasswordSecond(val)} />

        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
        </div>
    </div>
}

export default StepPassword;