import TextInput from "@/components/TextInput";
import { Auth } from "aws-amplify";
import { useState } from "react";
import Button from 'react-bootstrap/Button';

function EditMobileConfirm({ currentUser }) {
    const [code, setCode] = useState('');


    const handleConfirmClick = () => {
        //{"__type":"ExpiredCodeException","message":"Invalid code provided, please request a code again."}
        Auth.verifyUserAttributeSubmit(currentUser, 'phone_number', code)
            .then(() => {

            })
    }

    return <div>
        <div className={'ecc-text text-2xl font-semibold my-4'}>Enter Code</div>
        <p className={'mb-4'}>We've sent a code by SMS to your mobile number {currentUser?.attributes?.phone_number}</p>

        <div className={'mb-4'}>
            <TextInput label={'Code'} onChange={setCode} />
        </div>

        <p>Did not receive the code?</p>
        <a href={''}>Resend Code</a>



        <div className={'my-4'}>
            <Button variant="primary" onClick={handleConfirmClick}>
                Confirm
            </Button>
            <Button type={"secondary"} >
                Cancel
            </Button>
        </div>
    </div>
}

export default EditMobileConfirm;