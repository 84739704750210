import React, { useEffect, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function CheckBoxInput({ label, value, onChange }) {
    const [checked, setChecked] = useState(value);
    const [id] = useState(_uniqueId('input-'));

    const handleCheck = () => {
        setChecked(current => !current);
    }

    useEffect(() => {
        onChange(checked);
    }, [checked]);

    return <div className={'form-check'}>
        <input className={'form-check-input'} type={'checkbox'} checked={checked} id={id} onChange={handleCheck} />
        <label className={'form-check-label'} for={id}>
            {label}
        </label>
    </div>
}

export default CheckBoxInput;

/*
<div className={'form-check'}>
  <input className={'form-check-input" type={'checkbox" value={'" id={'flexCheckDefault'}>
  <label className={'form-check-label" for={'flexCheckDefault'}>
    Default checkbox
  </label>
</div>
*/

