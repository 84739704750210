import { useEffect, useState } from 'react';
import TextInput from "@/components/TextInput";
import { Routes, Route, useNavigate } from 'react-router';
import { Alert } from "react-bootstrap";
import Button from '@/components/Button';
import { Auth } from "aws-amplify";
import EditMobileConfirm from './EditMobileConfirm';
import PasswordInput from '@/components/PasswordInput';
import EditPasswordConfirm from './EditPasswordConfirm';
import { authLogOut } from '@/utils/authLogOut';
import { checkPassword } from '@/utils/checkPassword';
import ErrorContainer from '@/components/ErrorContainer';
import ErrorBox from '@/components/ErrorBox';

function EditPassword({ currentUser, refreshUser }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCompleteAlert, setShowCompleteAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const [updateError, setUpdateError] = useState(undefined);
    const [hasValidationError, setHasValidationError] = useState(false);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [passwordFeedback, setPasswordFeedback] = useState('')
    const nav = useNavigate();

    const handleUpdatePasswordClick = () => {

    }

    useEffect(() => {
        if (!password || !newPassword || !newPassword2) {
            setErrorMessage('All fields must be populated');
            setHasValidationError(true);
            return;
        }
        if (newPassword !== newPassword2) {
            setErrorMessage('Passwords do not match');
            setHasValidationError(true);
            return;
        }

        const passwordResult = checkPassword(newPassword);

        if (passwordResult.error) {
            setErrorMessage(passwordResult.message);
            setPasswordFeedback(passwordResult.feedback);
            setHasValidationError(true);
            return;
        }

        setHasValidationError(false);
    }, [newPassword, newPassword2])

    const handleModalCallback = (result) => {
        setShowConfirmModal(false);

        if (result) {
            Auth.changePassword(currentUser, password, newPassword)
                .then((result) => {
                    authLogOut().then(() => {
                        window.location.href = window.location.href.toString().replace(window.location.pathname, '');
                    })
                })
                .catch((ex) => {
                    setUpdateError(ex.message);
                    return;
                    if (ex.code === 'NotAuthorizedException') {
                        /* Current password incorrect */
                        setUpdateError('Current Password is incorrect');
                    }
                    else {
                        setUpdateError(ex.message)
                    }
                });
        }
    }

    const canSave = () => {
        return password !== '' && !hasValidationError;
    }


    return <div>
        {updateError && <ErrorBox>There was a problem updating your password:<br />{updateError}</ErrorBox>}

        <div className={'my-5 ecc-text text-2xl font-semibold'}>Password</div>

        <div className={'bg-neutral-100 p-5 border border-top border-x-0 border-b-0'}>
            Your password must:
            <ul className={'list-disc list-inside'}>
                <li>have a minimum 8 characters</li>
                <li>include at least 1 number</li>
                <li>include at least 1 lower case</li>
                <li>include at least 1 upper case</li>
                <li>include at least 1 special character</li>
            </ul>
        </div>

        <div className={'my-8'}>
            <span><strong>Last Updated: </strong>2 July 2022 1:09:23 PM</span>
        </div>

        <div className={'mb-4'}>
            <PasswordInput label={'Current Password'} onChange={setPassword} />
        </div>

        <div className={'mb-4'}>
            <PasswordInput label={'New Password'} onChange={setNewPassword} />
        </div>

        <div className={'mb-10'}>
            <PasswordInput label={'Re-enter new Password'} onChange={setNewPassword2} />
        </div>

        {hasValidationError && <ErrorBox>
            {errorMessage}<br />
            {passwordFeedback}
        </ErrorBox>}

        <div className={'my-8'}>
            <Button disabled={!canSave()} variant="primary" onClick={() => setShowConfirmModal(true)}>
                Update
            </Button>
        </div>

        <EditPasswordConfirm showModal={showConfirmModal} callback={handleModalCallback} />
    </div>
}

export default EditPassword;