import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router';
import { Auth } from "aws-amplify";


import EditEmail from './EditEmail';
import EditMobile from './EditMobile';
import EditMobileConfirm from './EditMobileConfirm';
import EditName from './EditName';
import EditEmailLinkSent from './EditEmailLinkSent';
import EditPassword from './EditPassword';

function EditProfile() {
    const [currentUser, setCurrentUser] = useState();

    const refreshUser = () => {
        Auth.currentAuthenticatedUser().then((user) => {
            setCurrentUser(user);
        });
    }

    useEffect(() => {
        refreshUser();
    }, []);



    return <Routes>
        <Route path={'/'} element={<div>root</div>} />
        <Route path={'/name'} element={<EditName currentUser={currentUser} refreshUser={refreshUser} />} />
        <Route path={'/email'} element={<EditEmail currentUser={currentUser} />} />
        <Route path={'/email/linksent'} element={<EditEmailLinkSent currentUser={currentUser} />} />
        <Route path={'/mobile'} element={<EditMobile currentUser={currentUser} />} />
        <Route path={'/mobile/confirm'} element={<EditMobileConfirm currentUser={currentUser} />} />
        <Route path={'/password'} element={<EditPassword currentUser={currentUser} />} />
        <Route path={'/mfa'} element={<div>2fa</div>} />
    </Routes>
}

export default EditProfile;