import React from 'react';
import { Auth } from 'aws-amplify';
import { eraseCookie } from './utils/cookieHelper';



function ErrorPage({ errorCode, errorMessage }) {
    const handleLogout = async () => {
        eraseCookie("id_token");
        eraseCookie("access_token");
        eraseCookie("refresh_token");
        localStorage.removeItem('client-id');

        await Auth.signOut();
        window.location.pathname = '/';
    }

    return <div>
        There has been an problem logging you in. {errorCode} {errorMessage}
        <button onClick={() => handleLogout()}>Logout</button>
    </div>
}

export default ErrorPage;