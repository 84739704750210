import React, { useState, useEffect } from 'react';
import success from '@/assets/success.svg';

function Complete(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    return <div>

        <div className={'complete-container'}>
            <div className={'complete-container-success'}>
                <img src={success} />
            </div>
            <div className={'complete-container-text'}>Account Created!</div>
            <div className={'complete-container-subtext'}>Your account has been created successfuly on FoodTrader.. </div>
        </div>
        <div className={'block-button-container'}>
            <button type={'button'} className={'btn btn-primary btn-block'}>Go to my account</button>
        </div>

    </div>
}


export default Complete;